







import { Component, Vue } from "vue-property-decorator";
import Download from "../components/Download.vue";

@Component({
  components: {
    Download,
  },
})
export default class NotFound extends Vue {}
